/* Open Sans */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/Open_Sans/static/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth\,wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/Open_Sans/static/OpenSans-BoldItalic.ttf') format('truetype');
}

/* PT Serif */
@font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/PT_Serif/PTSerif-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/PT_Serif/PTSerif-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/PT_Serif/PTSerif-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/PT_Serif/PTSerif-BoldItalic.ttf') format('truetype');
}