@mixin caret-transition($modifier-class: 'collapsed') {
  .svg-inline--fa {
    transition: transform $transition;
  }

  &.#{$modifier-class} {
    .svg-inline--fa {
      transform: scaleY(-1);
    }
  }
}
