.locationlist {
  .location-item {
    background-color: $brand-primary-lightest;
    p {
      margin-bottom: 0;
    }
  }

  .location-item-image-wrapper {
    position: relative;
    min-height: 150px;
  }

  .location-item-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include object-fit(cover);
  }
}
