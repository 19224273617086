.content-sections {
  @include media-breakpoint-down(lg) {

    // Fix hiding of anchor text when scrolling to it
    @supports (position: sticky) {
      $mobile-nav-height: 56px;

      .scroll-fix {
        margin-top: -$mobile-nav-height - 16px;
        padding-top: $mobile-nav-height + 16px;
      }
    }

    // remove container padding to achieve full width nav
    padding-left: 0;
    padding-right: 0;

    > .row {
      margin-right: 0;
      margin-left: 0;

      > .col,
      > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;

        > .content-sections-content {
          padding-right: calc($grid-gutter-width / 2);
          padding-left: calc($grid-gutter-width / 2);
        }
      }
    }
  }
}

.content-sections-nav {
  border-top: 1px solid $border-color;

  .nav-item {
    border-bottom: 1px solid $border-color;
    width: 100%;

    // Subnavigation
    .nav {
      border-top: 0;
      margin-left: $spacer;

      .nav-item {
        border-bottom: 0;
      }
    }
  }

  .nav-link {
    padding-left: 0;
    padding-right: 0;
    color: $body-color;

    &:hover, &:focus {
      color: $link-color;
    }

    @include caret-transition;
  }
}

.content-sections-mobile-nav {
  margin-bottom: $spacer;

  @supports (position: sticky) {
    max-height: 100vh;
    overflow-y: auto;
  }

  a {
    color: $white;
  }

  .nav {
    padding-left: calc($grid-gutter-width / 2);
    padding-right: calc($grid-gutter-width / 2);
  }
  .nav-item {
    border-bottom: 2px solid $brand-primary-dark;

    &:last-child {
      border-bottom: 0;
    }
  }
  .nav-link {
    padding: $spacer 0;
  }
}

.content-sections-mobile-nav-toggle {
  display: block;
  padding: calc(2 * $nav-link-padding-y) calc($grid-gutter-width / 2);

  background-color: $brand-primary;
  font-weight: $btn-font-weight;
  font-size: $font-size-sm;
  text-transform: uppercase;

  @supports (position: sticky) {
    background-color: rgba(77, 107, 140, .9);
    backdrop-filter: blur(2px);
    transition: background-color $transition-fast;

    &:hover, &:focus, &:not(.collapsed) {
      background-color: $brand-primary;
    }
  }

  @include caret-transition;
}

#contentSectionMobileNavCollapse {
  background-color: $brand-primary;
}
