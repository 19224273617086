/****************************************
 *   Temporary Responsive Table Fix     *
 ****************************************/

table.zb-responsive-table {
    margin: 25px 0;
}

table.zb-responsive-table tr.zb-rowspan {
    border-bottom: 0px !important;
}

table.zb-responsive-table tr td[rowspan] {
    padding-top: 15px;
    vertical-align: top !important;
}

@media (max-width: 800px) {

    table.zb-responsive-table tr td {
        display: flex;
        width: 100% !important;

        &>* {
            display: flex;
            width: 100%;
        }

        &:first-child {
            padding-top: 20px;
            display: inline-block;
        }

        &:nth-child(2) {
            display: none;
        }

        &:nth-child(4) {
            padding-bottom: 0;
        }

        &:last-child {
            padding: 0 6px 20px;
        }
    }


    table.zb-responsive-table tr {
        &.zb-rowspan {
            border: 0 !important;
            margin-bottom: 20px;

            td:last-child {
                padding-bottom: 6px;
            }

            &+tr td:first-child {
                display: none;
            }

            &+tr td:nth-child(2) {
                display: inline-block;
                padding-top: 20px;
            }

            &+tr td:nth-child(3) {
                padding-bottom: 0;
            }

            &+tr td:last-child {
                padding-bottom: 20px;
            }
        }
    }
}