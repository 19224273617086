.footer-content {
  color: $white;
  h3 {
    font-family: $font-family-base;
    text-transform: uppercase;
    font-size: $font-size-base;
  }
  a {
    color: $white;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
  .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  address {
    padding-top: $nav-link-padding-y;
  }
}

.footer-brand {
  color: $white;

  .logo {
    height: 57px;
  }
}
