div.post-detail {
  img {
    max-width: 100%;
    display: block;
    margin: 1em auto 1em auto;
  }
  figure.captioned img {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: baseline;
  }
  div.meta_data div.categories-list a {
    font-size: 80%;
  }
}

ul.post-list {
  list-style-type: none;
  img {
    max-width: 100%;
  }
  figure.captioned img {
    vertical-align: baseline;
  }
  div.meta_data div.categories-list a {
    font-size: 80%;
  }
}

ul.post-list.first-page {
  li.post-list-item:first-child {
    margin-top: 50px;
    margin-bottom: 75px!important;
    div.post-preview {
    background:
      linear-gradient(to bottom left, #4d6b8c 50%, transparent 50%) 100% 0/30px 30px,
      linear-gradient(to bottom right, #4d6b8c 50%, transparent 50%) 0 0/30px 30px,
      linear-gradient(to top left, #4d6b8c 50%, transparent 50%) 100% 100%/30px 30px,
      linear-gradient(to top right, #4d6b8c 50%, transparent 50%) 0 100%/30px 30px;
     background-repeat:no-repeat;
    }
  }
}

.feed_icon {
  color: orange;
}
