.exhibitdetail {
  .showroom {
    background-color: $brand-brown;
    color: $white;
  }
  .events {
    background-color: $brand-primary-lighter;

    .date {
      font-weight: $font-weight-bold;
    }
  }
  .files-list {
    font-weight: $font-weight-bold;
  }
}
