main {
  padding-bottom: 2.5rem;
}

// Set max width to fluid container
.container-fluid {
  @include media-breakpoint-up(xl) {
    max-width: map_get($container-max-widths, 'xl');
  }

  &.container-nested {
    padding-left: 0;
    padding-right: 0;
  }
}

.btn {
  white-space: normal; // allow multi-line button texts
  text-transform: uppercase;
  font-size: $font-size-sm;
}

.btn-link {
  font-weight: $font-weight-bold;
}

.btn-outline-primary-invert {
  @include button-outline-variant($white, $primary);

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $primary;
  }
}

.btn-primary-invert {
  @include button-variant($white, $white, $brand-primary-light, $brand-primary-light, $brand-primary-light, $brand-primary-light);
  color: $primary;
  &:hover {
    color: $white;
  }
}

// pagination
.pagination {
  flex-wrap: wrap;
}
.page-link {
  margin-left: calc($spacer / 4);
  margin-right: calc($spacer / 4);
  margin-bottom: calc($spacer / 2);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.page-item:last-child .page-link,
.page-item:first-child .page-link {
  border-radius: 50%;
  margin-left: calc($spacer / 4);
  margin-right: calc($spacer / 4);
}

// Fix for displaying images correcting in Internet Explorer
// See: https://github.com/twbs/bootstrap/issues/25242
.figure {
  display: block;
}
.figure-img {
  margin-bottom: 0;
}
.figure-caption {
  background-color: $brand-brown;
  padding: calc($spacer / 2);
}

// Carousel
.carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: calc($spacer / 2);
  padding-bottom: calc($spacer / 2);
  background-color: rgba(132, 105, 99, .9);
  backdrop-filter: blur(2px);
  font-size: $font-size-sm;

  h3 {
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin-bottom: map_get($spacers, 1);
  }
}

.carousel-indicators {
  position: static;
  margin-top: $buffer;
  
  li {
  	padding-top: initial!important;
  	padding-bottom: initial!important;
  }
}

.richeditor-content .carousel-item img {
  $image-height: 500px;
  max-height: $image-height !important;
  object-fit: contain;
  @include object-fit(contain);

  /* Keep aspect-ratio relative to viewport width to avoid content wobbling */
  @media (max-width: 991px) {
    height: 60vw !important; /* height = 60% Viewport width */
  }
}

.carousel-control-prev,
.carousel-control-next {
  $control-size: 55px;
  transition: opacity $transition-fast;
  z-index: 10;

  span {
    width: $control-size;
    height: $control-size;
    border-radius: 50%;
    background-color: $brand-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $control-size - 20px;
  }
}
