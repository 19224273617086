.header {
  .navbar-brand img {
    height: 57px;
  }
  .navbar {
    // remove container padding on collapsed navbar
    .navbar-collapse {
      margin-left: calc($grid-gutter-width / -2);
      margin-right: calc($grid-gutter-width / -2);

      @include media-breakpoint-up(lg) {
        margin-left: 0;
        margin-right: 0;
      }

      > .navbar-collapse-content {
        @include media-breakpoint-down(lg) {
          background-color: $brand-primary;
          padding: calc($grid-gutter-width / 2);

          a {
            color: $white;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        .navbar-nav .nav-item {
          border-bottom: 2px solid $brand-primary-dark;

          .nav-link {
            padding-top: $spacer;
            padding-bottom: $spacer;
          }
        }
      }
    }

    @include media-breakpoint-only(lg) {
      .navbar-nav .nav-item .nav-link {
      	padding-left: $navbar-nav-link-padding-x * 0.6;
        padding-right: $navbar-nav-link-padding-x * 0.6;
      }
    }
  }
  .menu_underline {
    border-bottom: 2px solid $brand-primary-dark;
  }
  .menuextend {
  	padding-top: $spacer;
	padding-bottom: $spacer;
  }
}
