.teaser {
  color: $body-color;

  &:not(.no-border) {
    border: solid 1px $brand-grey-light;
  }

  a {
    display: block;
    color: $body-color;
    transition: box-shadow $transition-fast;

    .teaser-img {
      transition: opacity $transition-fast;
      opacity: .8;
    }

    &:hover, &:focus {
      box-shadow: $box-shadow-lg;

      .teaser-img {
        opacity: 1;
      }
    }
  }

  .teaser-content {
    padding: map_get($spacers, 2);

    .date {
      font-weight: $font-weight-bold;
    }
  }
}

.teaser-horizontal {
  .teaser-image-wrapper {
    position: relative;
    max-height: 300px;
    min-height: 300px;

    @include media-breakpoint-up('md') {
      max-height: initial;
      min-height: 300px;
    }
  }
  .teaser-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include object-fit('cover');
  }
}
