.website_search {
  form {
    min-width: 10em;
    div.input-group {
      input {
	    width: 7em !important;
      }
      button.input-group-text {
        color: $white;
        border-color: $brand-grey;
        background-color: $brand-grey;
      	&:hover {
          border-color: $brand-grey-dark;
          background-color: $brand-grey-dark;
       	}
      }
    }
  }
}

.custom_search {
  margin-bottom: 2em;
}
