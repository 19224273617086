h1, .h1 {
  text-align: center;
  word-break: break-word;
}
h2, .h2 {
  word-break: break-word;
}

/*
h1, h2, h3, h4, .lead {
  hyphens: auto;
}
*/

.lead {
  font-family: $headings-font-family;
  line-height: $lead-line-height;

  .btn {
    font-family: $font-family-base;
  }
}
