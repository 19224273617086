.homepage-tiles {
  @include media-breakpoint-down(md) {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .access {
    background-color: $brand-primary-lighter;
    p {
      line-height: 1.2em;
      letter-spacing: -0.05em;
    }
  }
  .access,
  .important-information-icon {
    text-align: center;
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;

    p {
      margin-bottom: 0;
    }
  }

  .location {
    background-color: $brand-primary-lightest;

    p {
      margin-bottom: 0;
    }
  }

  .important-information {
    color: $brand-brown;
    text-align: center;

    p.lead {
      font-size: $font-size-lg;
      margin-bottom: 0;
    }
  }

  .image-of-the-month {
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }

  .nice-to-know-teaser {
    .teaser-content {
      background-color: $brand-primary-lighter;
    }

    &.with-margin {
      margin-top: 0;

      @include media-breakpoint-up('sm') {
        margin-top: 66.66%;
      }
      @include media-breakpoint-up('md') {
        margin-top: 0;
      }
      @include media-breakpoint-up('lg') {
        margin-top: 66.66%;
      }
    }
  }
}
