.social-media {
  .social-media-icon {
    $social-media-icon-size: 55px;
    background-color: $brand-primary;
    border-radius: 50%;
    color: $white;
    width: $social-media-icon-size;
    height: $social-media-icon-size;
    font-size: calc($social-media-icon-size / 2);
    display: inline-block;
    line-height: $social-media-icon-size;
  }
}
