.language-navigation {
  .dropdown-menu {
    min-width: initial;
    .nav-item {
      a {
        width: 100%;
        &.activeLocale {
          background-color: $brand-grey;
        }
      }
    }
  }
  :not(.dropdown-menu) {
  	.nav-item {
	  border: 1px solid black;
	  border-style: inset;
	  margin: 0 1em 0 0;
	  a.activeLocale {
        background-color: $white;
      }
	}
  }
  .nav-item {
    a {
      font-weight: initial;
      color: $black !important;
      &:not(.activeLocale):hover {
        background-color: $brand-primary-lighter;
      }
    }
  }
}