/*
    SCSS for Audio Snippet
    themes/zb/partials/components/audio-snippet.htm
*/

figure.captioned.zb-audio {
    display: inline-flex;
    flex-direction: column;
    gap: 0.25rem 0;
    margin: 0.5rem 0 1rem;
    width: 32.9%;
    vertical-align: top;
    audio {
        justify-self: center;
        width: 100%;
    }
    figcaption {
        padding: 0 1.4rem;
        text-align: start;
    }
}

@media (max-width: 769px) {
    figure.captioned.zb-audio {
        display: grid;
        margin: 0.5rem auto 1.75rem;
        width: 100%;
    }
}