.fullwidth-link {
  .fullwidth-link-content-wrapper {
    background-color: $brand-primary-light;
    text-align: center;
    padding: $buffer-sm 0;
    transition: background-color $transition-fast;
  }

  a {
    display: block;
    color: $white;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    &:hover, &:focus {
      .fullwidth-link-content-wrapper {
        background-color: $brand-primary-light;
      }
    }
  }
}
