.contact {
  border: solid $border-color 1px;

  p {
    margin-bottom: 0;
  }
  .contact-name {
    border-bottom: solid $border-color 1px;
    padding: calc($grid-gutter-width / 2);

    @include media-breakpoint-up('md') {
      border-bottom: 0;
      border-right: 1px solid $border-color;
    }
  }
  .contact-detail {
    padding: calc($grid-gutter-width / 2);
    font-weight: $font-weight-bold;
  }
}
