.eventlisthome {
  background-color: $brand-primary-lightest;

  .event {
    padding-top: $spacer;
    padding-bottom: $spacer;
    border-bottom: 1px solid $border-color;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }

  .event-type {
    text-transform: uppercase;
    font-size: $font-size-sm;
  }

  .event-title {
    font-size: $font-size-base;
    font-family: $font-family-base;
  }

  .btn-link {
    padding-left: 0;
    padding-right: 0;
  }
}
