/* Temporary fix for responsive table */
@import './zb-responsive-table.scss';

@import '~object-fit-images/preprocessors/mixin';
@import 'bootstrap/bootstrap';
@import 'bootstrap/customize';
@import 'bootstrap/typography';
@import './mixins';
@import './utilities';
@import './fixed-ratio';
@import './fonts';
@import './richeditor';
@import './image-caption';

// components
@import 'components/header';
@import 'components/animated-navbar-toggler';
@import 'components/page-header';
@import 'components/language-navigation';
@import 'components/content-sections';
@import 'components/footer';
@import 'components/searchportal';
@import 'components/scroll-to-top';
@import 'components/locationlist';
@import 'components/socialmedia';
@import 'components/contact';
@import 'components/teaser';
@import 'components/eventdetail';
@import 'components/exhibitdetail';
@import 'components/teamlist';
@import 'components/openinghour';
@import 'components/fullwidth-link';
@import 'components/homepage-tiles';
@import 'components/eventlisthome';
@import 'components/forms';
@import 'components/websitesearch';
@import 'components/primo-search';
@import 'components/login-section';
@import 'components/news-blog';
@import 'components/audio-snippet';

html {
  -webkit-font-smoothing: antialiased;
}
