figure.captioned {
  text-align: center;
  font-style: italic;
  font-size: smaller;
  text-indent: 0;
  border: none;
  padding: 0;
}

img.with_captions {
  width: 100% !important;
  max-width: initial !important;
  height: 100% !important;
  margin: 0 !important;
  float: none !important;
  display: inline-block !important;
  vertical-align: initial !important;
}
