.openinghour-content {
  color: $white;
  padding: map_get($spacers, 3);
}

.openinghour-content a {
  color: $white;
}

.openinghour-item {
  background-color: $brand-brown;
}

.openinghour_stretch {
  height: 100%;
}

.openinghour-list {
  > li {
    padding-top: calc($spacer / 2);
    padding-bottom: calc($spacer / 2);
    border-bottom: solid 1px $brand-grey;
  }
}
