.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {color:#a94442}
.has-error .form-control {border-color:#a94442}
.has-error .form-control:focus {border-color:#843534}
.has-error .input-group-addon {color:#a94442;border-color:#a94442;background-color:#f2dede}
.has-error .form-control-feedback {color:#a94442}

.has-error {
	.help-block.form-field-error-label {
		background-color: #a94442;
		color: white;
		padding: 0.5rem;
	}
}

.form-group {
  margin-bottom: 1rem;
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .checkbox {
    display: inline-block;
  }
  
  .checkbox + .checkbox {
    margin-left: 0.5rem;
  }
  
  div.radio {
    position: relative;
    display: block;
    padding-left: 1.25rem;
    margin: 0.5rem;
  }
  
  div.responsiv-uploader-fileupload {
	color: rgba(140, 140, 140, 0.8);
    border: 1px dashed rgba(39, 41, 43, 0.15);
    border-radius: 3px;
    
    &:hover {
      transition: border 0.4s ease-out;
      border-color: #2581b8;
    }
    
    button.upload-button.dz-clickable {
      cursor: pointer;
      color: rgba(140, 140, 140, 0.8);
      padding: 1rem;
      width: 100%;
      box-shadow: none;
    }
  }

  &.required-field::before {
    content: "*";
    color: red;
    float: left;
    margin-right: 5px;
  }
}
