@mixin details-grid {
  display: grid;
  gap: 0 0.7rem;
  grid-template-columns: 1.25rem auto;
  grid-template-rows: auto; 
}

.eventdetail {
  .files-list {
    font-weight: $font-weight-bold;
  }

  .other-dates-list {
    li.active {
      font-weight: $font-weight-bold;
    }
  }
}

.eventinfo {
  color: $white;

    .venue {
      font-weight: $font-weight-bold;
      margin-bottom: 0;

      &.eventinfo-details-grid {
        @include details-grid;

        .icon {
          grid-column-start: 1;
          grid-row-start: 1;
          justify-self: center;
        }
        .text {
          grid-column-start: 2;
          grid-row-start: 1;
        }
      }
    }
  
    .calendar-link {
      
      &.eventinfo-details-grid {
        @include details-grid;

        .icon {
          grid-column-start: 1;
          grid-row-start: 1;
          justify-self: center;
        }
        .text {
          grid-column-start: 2;
          grid-row-start: 1;
        }
      }

      a {
        color: #fff;
      }
    }

  .eventinfo-wrapper {
    background-color: $brand-brown;
  }

  .eventinfo-image-wrapper {
    position: relative;
    min-height: 150px;
  }

  .eventinfo-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include object-fit(cover);
  }
}
