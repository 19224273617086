.searchportallist,
.searchportalsearchform {
  .searchform {
    color: $white;
  }

  .searchkeyword #keyword {
    height: 90%;
  }

  .filters {
    .dropdown {
      @include caret-transition('show');
    }
    .dropdown-menu {
      max-height: 400px;
      max-width: 100%;
      min-width: 100%;
      overflow-y: auto;

      @include media-breakpoint-up(sm) {
        min-width: 280px;
      }
    }
    .dropdown-item {
      white-space: normal;

      .form-check-label {
        display: block;
      }
    }
    .dropdown-toggle {
      .badge {
        @include transition($btn-transition);
        color: $primary;
        background-color: $white;
      }
      &:hover {
        .badge {
          background-color: $primary;
          color: $white;
        }
      }
    }
    .show > .dropdown-toggle .badge {
      color: $white;
      background-color: $primary;
    }
  }

  .searchform-submit {
    color: $white;
    font-weight: $font-weight-normal;
  }
}

.searchportallist {

  .pagination {
    /* Alphabetic pagination */
    --btn-shrink: 0.8;
    --btn-margin: 0.3rem;
    align-items: center;
    background-color: #fff;
    gap: 1rem;
    height: 5.1rem;
    padding: 1rem 0;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 99;

    @media screen and (min-width: 992px) { // lg
        .col-lg-1 {
          width: 6rem !important;
      }
    }

    @media screen and (min-width: 768px) { // md
        .col-2 {
          width: 6.5rem !important;
      }
    }

    @media screen and (max-width: 576px) { // sm
      .col-2 {
        width: 4rem !important;
      }
      .zb-searchportal-indicator-first-wrapper,
      .zb-searchportal-indicator-last-wrapper {
        display: none;
      }
    }

    .zb-searchportal-indicator,
    .zb-searchportal-indicator.first,
    .zb-searchportal-indicator.previous,
    .zb-searchportal-indicator.next,
    .zb-searchportal-indicator.last {
      height: 2.5rem;
      padding: .3rem 0;
      transform: scale(var(--btn-shrink));
      transition: transform 0.2s ease-in-out;
      width: 2.5rem;
      &:hover,
      &:active,
      &:focus {
        transform: scale(1);
      }
      &#currentpage {
        margin: 0 0.3rem !important;
        transform: unset;
      }
    }

    .zb-searchportal-indicator.first,
    .zb-searchportal-indicator.previous,
    .zb-searchportal-indicator.next,
    .zb-searchportal-indicator.last {
      font-size: 1.7rem;
      line-height: 0.7;
    }

    .col.pagination-indicator-scroll {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      height: 4.9rem;
      overflow-x: scroll;
      padding: 1.2rem var(--btn-margin) 0;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        display: thin;
      }

      .active {
        cursor: grabbing;
        cursor: -webkit-grabbing;
        transform: scale(1.1);
      }

      &:hover {
        cursor: grab;
      }

      .zb-searchportal-indicator {
        
        &span {
          &:hover {
            transform: scale(--btn-shrink);
          }
        }

        &a:focus {
          margin: 0 0.3rem !important;
        }

        &:hover {
          cursor: grab;
        }

        &.me-md-2 {
          margin-right: 0 !important; //var(--btn-margin) !important;
        }

        &.ms-md-2 {
          margin-left: 0 !important; //var(--btn-margin) !important;
        }

        &.btn {
          line-height: 1.75;
        }

        &.disabled {
          border-color: rgb(62, 86, 112, .24);
        }

      }
    }
  }

  .zb-searchportal-list-header {
    min-height: 2rem;
  }

  .sortby {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-position-x: calc(100% - 0.4rem);
    width: calc(100% + 0.5rem);
  }

  .list-group-item {
    border-left: 0;
    border-right: 0;
    padding-bottom: 0;

    a.searchportal-link {
      color: $body-color;

      &:hover, &:focus {
        color: $link-color;
      }
    }

    .zb-top-match svg {
      color: rgb(158, 132, 76);
      height: 20px;
      position: relative;
      top: -3px;
      width: 20px;
    }

    .zb-meta-row {
      display: flex;
      
      .zb-badge-col {
        display: flex;
        gap: 0 0.8rem;

        .zb-badge {
          align-items: center;
          border-radius: 3px;
          display: inline-flex;
          font-size: 65%;
          gap: 0.15rem;
          height: 1.4rem;
          padding: .15rem 0;
    
          &.login {
            color: $primary;
            .zb-badge-icon {
              content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$primary}" class="bi bi-key-fill" viewBox="0 0 16 16"><path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2M2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/></svg>');
            }
          }

          &.open-access {
            color: $primary;
            .zb-badge-icon {
              content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$primary}" class="bi bi-unlock-fill" viewBox="0 0 16 16"><path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2"/></svg>');
            }
          }

          &.vpn-network {
            color: $primary;
            .zb-badge-icon {
              content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$primary}" class="bi bi-diagram-3-fill" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5z"/></svg>');
            }
          }
    
          &.top {
            color: $primary;
            .zb-badge-icon {
              content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$primary}" class="bi bi-award-fill" viewBox="0 0 16 16"><path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864z"/><path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/></svg>');
            }
          }
        }
      }
    }

    .arrow {
      color: $brand-grey-dark;

      &:hover, &:focus {
        color: $link-color;
      }
    }

    .description-collapse-link {
      padding: 0 0 0.3rem 0;

      @include caret-transition;
    }

    .collapse.show {
      padding-bottom: 0.5rem;
    }
  }
}
