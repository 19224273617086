.break-word {
    word-break: break-word;
}

.last-child-margin-fix > :last-child {
  margin-bottom: 0;
}

.border-underline {
  .nav-link {
    .label {
      border-bottom: 1px solid transparent;
      padding-top: $nav-link-padding-y;
      padding-bottom: $nav-link-padding-y;
    }

    &:hover,
    &:focus,
    &.active {
      text-decoration: none;

      .label {
        border-bottom-color: $navbar-light-hover-color;
      }
    }
  }
}

// Heading spacing
h2, h3 {
  &.heading-spaced {
    margin-bottom: map_get($spacers, 5);
  }
}

// List spacing
ul, ol {
  &.list-spaced > li {
    padding-top: $list-spacing;
    padding-bottom: $list-spacing;
  }
}

// Brand spacing
.brand-py {
  padding-top: $buffer;
  padding-bottom: $buffer;
}
.brand-py-sm {
  padding-top: $buffer-sm;
  padding-bottom: $buffer-sm;
  padding-right: 15px;
  padding-left: 15px;
}
.brand-py-lg {
  padding-top: $buffer-lg;
  padding-bottom: $buffer-lg;
}
.brand-p {
  padding: $buffer;
}
.brand-p-sm {
  padding: $buffer-sm;
}
.brand-p-lg {
  padding: $buffer-lg;
}

.brand-mb {
  margin-bottom: $buffer;
}
.brand-mb-sm {
  margin-bottom: $buffer-sm;
}
.brand-mb-lg {
  margin-bottom: $buffer-lg;
}
.brand-mt {
  margin-top: $buffer;
}
.brand-mt-sm {
  margin-top: $buffer-sm;
}
.brand-mt-lg {
  margin-top: $buffer-lg;
}
