$scroll-to-top-size: 55px;
$scroll-to-top-gutter: 20px;

.scroll-to-top {
  transition: opacity $transition-fast, right $transition;
  position: fixed;
  bottom: $scroll-to-top-gutter;
  right: -($scroll-to-top-size + $scroll-to-top-gutter);
  z-index: 100;
  width: $scroll-to-top-size;
  height: $scroll-to-top-size;
  background: $brand-primary;
  color: $white;
  border-radius: 50%;
  opacity: .7;
  line-height: $scroll-to-top-size;
  font-size: $scroll-to-top-size - 20px;
  text-align: center;

  &:hover, &:focus {
    color: $white;
    opacity: .9;
  }

  &.show {
    right: $scroll-to-top-gutter;
  }
}
