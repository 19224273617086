.richeditor-content {
  /*
   * Text
   */
  .oc-text-gray {
    color: $brand-grey-dark !important;
  }
  .oc-text-bordered {
    border-top: solid 1px $border-color;
    border-bottom: solid 1px $border-color;
    padding: 10px 0;
  }
  .oc-text-spaced {
    letter-spacing: 1px;
  }
  .oc-text-uppercase {
    text-transform: uppercase;
  }

  // Add buffer to headings
  > h2, h3, h4 {
    &:not(:first-child) {
      margin-top: $buffer;
    }
  }

  // Add padding to lists
  ul, ol {
     > li {
      padding-top: $list-spacing;
      padding-bottom: $list-spacing;
    }
  }

  /*
   * Links
   */
  a.oc-link-strong {
    font-weight: $font-weight-bold;
  }
  a.oc-link-green {
    color: green;
  }

  /*
   * Table
   */
  // correction for Bootstrap 5 reboot
  table[border="1"] td,
  table[border="1"] th {
    border-width: 1px;
  }
  
  table.oc-dashed-borders td,
  table.oc-dashed-borders th {
    border-style: dashed;
    border-width: 1px;
  }
  table.oc-alternate-rows tbody tr:nth-child(2n) {
    background: $brand-grey-light;
  }

  /*
   * Table cell
   */
  table td.oc-cell-highlighted,
  table th.oc-cell-highlighted {
    border: 1px double red;
  }
  table td.oc-cell-thick-border,
  table th.oc-cell-thick-border {
    border-width: 2px;
  }

  /*
   * Images
   */
  img.oc-img-rounded {
    border-radius: 100%;
    background-clip: padding-box;
  }
  img.oc-img-bordered {
    border: solid 10px $border-color;
    box-sizing: content-box;
  }

  img {
    $image-margin: $spacer;

    // Responsive images when content width is too small
    max-width: 100%;
    height: auto !important;

    &.fr-dib {
      margin: auto;
      display: block;
      float: none;
      vertical-align: top;

      &.fr-fil {
        margin-left: 0;
      }
      &.fr-fir {
        margin-right: 0;
      }
    }
    &.fr-dii {
      display: inline-block;
      float: none;
      vertical-align: bottom;
      margin-left: $image-margin;
      margin-right: $image-margin;
      max-width: calc(90%);

      &.fr-fil {
        float: left;
        margin: calc($image-margin / 2) $image-margin calc($image-margin / 2) 0;
        max-width: calc(95%);
      }
      &.fr-fir {
        float: right;
        margin: calc($image-margin / 2) 0 calc($image-margin / 2) $image-margin;
        max-width: calc(95%);
      }
    }
  }
  
  /*
   * Videos
   */
  .fr-video {
    $image-margin: $spacer;
    
    // Responsive videos when content width is too small
    max-width: 100%;
    height: auto !important;
    
    text-align: center;
    /*
    position: relative;
    &.fr-rv {
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
    }
    &.fr-rv > iframe,
    &.fr-rv object,
    &.fr-rv embed {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }*/
    &.fr-dvb {
      display: block;
      clear: both;
      margin: auto;
      float: none;
      vertical-align: top;
    }
    &.fr-dvb.fr-fvl {
      text-align: left;
      margin-left: 0;
    }
    &.fr-dvb.fr-fvr {
      text-align: right;
      margin-right: 0;
    }
    &.fr-dvi {
      display: inline-block;
      float: none;
      vertical-align: bottom;
      margin-left: $image-margin;
      margin-right: $image-margin;
      max-width: calc(90%);
    }
    &.fr-dvi.fr-fvl {
      float: left;
      margin: calc($image-margin / 2) $image-margin calc($image-margin / 2) 0;
      max-width: calc(95%);
    }
    &.fr-dvi.fr-fvr {
      float: right;
      margin: calc($image-margin / 2) 0 calc($image-margin / 2) $image-margin;
      max-width: calc(95%);
    }
  }
}
