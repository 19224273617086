.fixed-ratio {
  position: relative;
  width: 100%;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: 100%; // 1:1 ratio
  }
}

.fixed-ratio-2-to-1:before {
  padding-top: 50%; // 2:1 ratio
}

.fixed-ratio-16-to-9:before {
  padding-top: 56.25%; // 16:9 ratio
}

.fixed-ratio-3-to-2:before {
  padding-top: 66.66%; // 3:2 ratio
}

.fixed-ratio-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
}

img.fixed-ratio-image {
  position: absolute;
  left: 0;
  min-width: 100%;
  min-height: 101%; // the 1% is needed to fix possible subpixel errors from translating the image by 50%
  max-width: 100%;
  height: auto;
  object-fit: cover;
  @include object-fit(cover);

  // center image vertically (object-fit centers image automatically but padding-top from fixed ratio interferes with this behaviour)
  top: 50%;
  transform: translate3d(0, -50%, 0);
}
