.page-header {
  .header-image {
    position: relative;
    min-width: 100%;
    max-width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: 400px;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      @include object-fit(cover);

      @include media-breakpoint-up(lg) {
        height: auto;
      }

      @media (min-width: 1950px) {
        max-width: 1950px;
        left: calc(50% - (1950px / 2));
      }

    }

    .header-text {
      margin-bottom: 0;
      text-align: center;
      color: $white;
      text-shadow: 1px 1px 20px $brand-black;
      z-index: 10;
    }
  }

  .lead {
    text-align: center;
  }
}

.homepage {
  .page-header {
    .header-image {
      height: 400px;

      @include media-breakpoint-up(lg) {
        height: 500px;
      }
    }
  }
}

.homepage {
  .header-text{
    @include media-breakpoint-down(sm) {
      font-size: 2.5rem;
      word-break: keep-all;
    }
  }
}
