/**
 * Animated navbar toggler for Bootstrap 4
 *
 * Example:
 *
 * <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#mainNavigationCollapse" aria-controls="mainNavigationCollapse" aria-expanded="false" aria-label="Toggle navigation">
 *    <span class="icon-bar"></span>
 *    <span class="icon-bar"></span>
 *    <span class="icon-bar"></span>
 * </button>
 */
.navbar-toggler {
  $navbar-toggler-bar-height: 2px;
  $nabbar-toggler-bar-gutter: 6px;

  &:hover {
    .icon-bar {
      background-color: $link-hover-color;
    }
  }

  .icon-bar {
    width: 24px;
    height: $navbar-toggler-bar-height;
    background-color: $link-color;
    display: block;
    transition: all $transition-fast;
    margin-top: $nabbar-toggler-bar-gutter;
    position: relative;
    transform: rotate(0);
    top: 0;

    &:nth-of-type(1) {
      margin-top: 0;
    }
  }

  &:not(.collapsed) {
    .icon-bar {
      &:nth-of-type(1) {
        transform: rotate(45deg);
        top: $navbar-toggler-bar-height + $nabbar-toggler-bar-gutter; // center of the 3 bars
      }
      &:nth-of-type(2) {
        background-color: transparent;
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg);
        top: -($navbar-toggler-bar-height + $nabbar-toggler-bar-gutter); // center of the 3 bars
      }
    }
  }
}
