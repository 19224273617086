.primo-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
  
  #primoQueryRaw {
    background-color: rgba(255,255,255,0.9);
    &:hover {
     background-color: rgba(255,255,255,1);
    }
  }
}