.teamlist {
  .member-list {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
    li {
      border-bottom: solid 1px $border-color;
      padding-top: calc($spacer / 2);
      padding-bottom: calc($spacer / 2);
    }
    .row {
      align-items: center;
      div:first-child img {
	    width: 4em !important;
	    height: 4em !important;
	    max-width: 4em;
	    max-height: 4em;
      }
    }
    p.executive_board_member_string {
	  font-style: italic;
    }
  }
}

.teamlist_full_portrait {
  .tooltip-inner {
	background-color: white;
	padding: 5px;
	border: 1px solid black;
	img {
	  max-width: 100%;
    }
  }
  &.tooltip.show {
    opacity: 1;
  }
}
